import './CardProject.css';
import {useState} from 'react';

export default function CardProjects({infoCard}) {
    const[infoShow,setInfoShow]=useState('');
    const[flagShow,setFlagShow]=useState(false);

    const handlershowDesc=()=>{
        setFlagShow(!flagShow)
        if(flagShow === true){
            setInfoShow("allInfo")
        }else{
            setInfoShow('')
        }
    }
    
    return (
    <div className={"card-container position-relative "+ infoShow}  onClick={()=>handlershowDesc()}  >
            <img className='position-relative  z-0 w-100  card-img' src={infoCard.image} alt=""/>
            <div className={'position-absolute  bottom-0 start-0  z-1 info-section w-100 rounded-bottom-2 d-flex flex-column justify-content-center align-items-center '+ infoShow} style={{cursor:"pointer"}}>
                <h3 className='project-name '>{infoCard.name}</h3>
                <p className='project-desc text-center pt-5 px-5'>{infoCard.description}</p>  
                <a className='btn btn-primary border-0 link-go text-decoration-none text-black' href={infoCard.link}>Go visit!</a>  
            </div>
           
        </div>
    )
}