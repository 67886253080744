import { useState } from "react";
import CardProjects  from "../Card-Project";

import './Projects.css'
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';


export default function Projects() {

    const [projects]=useState([{
            id:'1',
            name:'UntangligWeb3',
            description:'UntanglingWeb3 is a site that allows the user to access to podcast and info about the UntanglingWeb3 group. Is a dynamic site built with React width use of an API, offering a user-friendly interface',
            image:'./images/projects/untanglingWeb3.png',
            link:'https://untanglingweb3.com/'
        },{
            id:'2',
            name:'Somos Noma',
            description:'Noma is a website that offers the user information about the project, which unites digital nomadic women in Latin America. It is made in React and presents animations created from scratch',
            image:'./images/projects/noma.png',
            link:'https://somos-noma-landing-demo.netlify.app/'
        },{
            id:'3',
            name:'Living Calafate',
            description:'This website is made for Living Calafate company, wich offer seamless transfers to top tourist destinations in Calafate.Its made width React, Formspree and translation applying diferent tools',
            image:'./images/projects/living-calafate.png',
            link:'https://living-calafate.netlify.app/'
        },{
            id:'4',
            name:'Rick&Morty project',
            description:'Rick&Morty is a project made in React intended as an exercise for my students, in which they can learn to use calls to APiS, information management and use of libraries and frameworks',
            image:'./images/projects/rick&morty.png',
            link:'https://rick-and-morty-react-proyect.netlify.app/'
        },{
            id:'5',
            name:'Gifos',
            description:'One of my favorite projects, created with a retro design, which allows you to create, view, store and download your own gifs. It is made completely in HTML, CSS and JS, with the use of an API.',
            image:'./images/projects/gifos.png',
            link:'https://gifos-proyect.netlify.app/'
        },{
            id:'6',
            name:'MB Consulting',
            description:'oficial site for the consulting ,that gives technical and professional advice for companies in the food sector.This site is made with vite and ReactJS',
            image:'./images/projects/mbconsulting.png',
            link:'https://www.mbconsultingz.com.ar'
        }]);


    return (
        <section className="padding-section section" id="projects">
            <h2 className="subtitle  text-center">See some projects</h2>

            <div className="h-75 w-100 contianer-distance d-flex flex-wrap gap-2 justify-content-center">

               
            
                {projects.map((project)=>{
                return <CardProjects key={project.id} infoCard={project} />})}              
            
                
            </div>


        </section>
    )
}